export default function getImageUrl(guessedCountry) {
  //check if URL already cached
  if (
    Object.prototype.hasOwnProperty.call(this.imageUrlCache, guessedCountry)
  ) {
    return this.imageUrlCache[guessedCountry];
  } else {
    try {
      // Fetch the image URL
      const country = this.countryData.find(
        (country) => country.name === guessedCountry
      );
      if (country) {
        const countryPrefix = country.data.prefix;
        const imageUrl = `https://teuteuf-dashboard-assets.pages.dev/data/common/flags/${countryPrefix}.svg`;

        // Cache the fetched URL
        this.imageUrlCache[guessedCountry] = imageUrl;

        return imageUrl;
      } else {
        // Handle case where country data is not found
        console.error("Country data not found for:", guessedCountry);
        return "";
      }
    } catch (error) {
      console.error("Error fetching image URL:", error);
      return "";
    }
  }
}
