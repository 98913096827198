<template>
  <div class="popular-answer-board">
    <div class="board">
      <!-- Column categories -->
      <div class="row" style="display: flex; align-items: flex-end">
        <div
          v-for="(column, colIndex) in gameData.columns"
          :key="'col-' + colIndex"
          class="category"
        >
          {{ column.name }}
        </div>
      </div>
      <!-- Row categories and filled board -->
      <div v-for="(row, rowIndex) in filledBoard" :key="rowIndex" class="row">
        <!-- Row category -->
        <div class="category row-category">{{ gameData.rows[rowIndex].name }}</div>
        <!-- Filled board cells -->
        <div v-for="(cell, colIndex) in row" :key="colIndex" class="box">
          <div class="cell-content">
            <!-- Display the rarity at the top -->
            <div
              v-if="cell.rarity"
              class="rarity"
              :style="{ backgroundColor: getRarityColor(cell.rarity) }"
            >
              {{ getRarityText(cell.rarity) }} - {{ cell.rarity }}%
            </div>
            <!-- Display the country flag image -->
             <div class="flag-container">
               <img
                 :src="getImageUrl(cell.name)"
                 alt="Country Flag"
                 v-if="cell.name"
                 class="country-flag"
               />
             </div>
            <div v-if="!cell.name" class="no-data">No data</div>
            <!-- Display the country name at the bottom -->
            <div class="country-name">{{ cell.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getImageUrl from "@/utils/getImageUrl";

export default {
  props: {
    board: Array,
    liveRarityData: Object,
    gameData: Object,
    countryData: Object,
  },
  data() {
    return {
      imageUrlCache: {},
    };
  },
  computed: {
    // Fill board with guessed countries and rarity percentages
    filledBoard() {
      const filledBoard = [];
      for (let rowIndex = 0; rowIndex < this.board.length; rowIndex++) {
        filledBoard[rowIndex] = [];
        for (
          let colIndex = 0;
          colIndex < this.board[rowIndex].length;
          colIndex++
        ) {
          const matchBox =
            "match_box_" +
            (rowIndex * this.board[rowIndex].length + colIndex + 1);
          const matchData = this.liveRarityData[matchBox];
          let mostPopularAnswer = "";
          let highestCount = 0;
          for (const [answer, count] of Object.entries(matchData)) {
            if (answer !== "total" && count > highestCount) {
              mostPopularAnswer = answer;
              highestCount = count;
            }
          }
          const total = matchData.total;
          const rarityPercentage = total
            ? ((highestCount / total) * 100).toFixed(1)
            : 0;
          filledBoard[rowIndex][colIndex] = {
            name: mostPopularAnswer,
            rarity: rarityPercentage,
          };
        }
      }
      return filledBoard;
    },
  },
  methods: {
    getImageUrl,
    // Set the color based on the rarity percentage
    getRarityColor(rarityPercentage) {
      if (rarityPercentage >= 30) {
        return "rgb(101, 208, 101)";
      } else if (rarityPercentage >= 20) {
        return "rgb(101, 139, 208)";
      } else if (rarityPercentage >= 10) {
        return "rgb(150, 112, 221)";
      } else if (rarityPercentage >= 5) {
        return "rgb(221, 112, 197)";
      } else {
        return "rgb(225, 53, 53)";
      }
    },

    // Set the rarity text based on the rarity percentage
    getRarityText(rarityPercentage) {
      if (rarityPercentage >= 30) {
        return "Common";
      } else if (rarityPercentage >= 20) {
        return "Uncommon";
      } else if (rarityPercentage >= 10) {
        return "Rare";
      } else if (rarityPercentage >= 5) {
        return "Epic";
      } else {
        return "Legendary";
      }
    },
  },
};
</script>

<style scoped>
.popular-answer-board {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.board {
  display: flex;
  flex-direction: column;
  margin-left: 120px;
  gap: 0;
}

.row {
  display: flex;
  align-items: center;
  gap: 5px;
}

.row:not(:first-child) {
    margin-bottom: -5px;
  }

.row:first-child {
  margin-left: 145px;
}

.category {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  width: 140px;
  min-height: 50px;
  max-height: 140px;
  margin-top: 5px;
  color: rgb(223, 223, 223);
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px;
  box-sizing: border-box;
  border-radius: 6px;
}

.box {
  width: 140px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 2px solid transparent;
  border-radius: 6px;
  pointer-events: none;
  box-sizing: border-box;
  padding: 4px;
}

.cell-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 100%;
}

.rarity {
  width: 100%;
  border-radius: 3px;
  font-size: 10px;
  color: white;
  font-weight: bold;
  line-height: 12px;
  padding: 2px 0;
}

.country-name {
  width: 100%;
  border-radius: 3px;
  font-size: 10px;
  color: white;
  font-weight: bold;
  background-color: #373768;
  line-height: 12px;
  padding: 4px 0;
}

.flag-container{
  width: 100%;
  flex-grow: 1;
  overflow: hidden;
  padding:4px;
}
.country-flag {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 992px) {
  .board {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .board {
    display: flex;
    flex-direction: column;
    margin-left: -273px;
  }
  .row {
    margin-left: 45px;
    gap: 4px;
  }
  .row:not(:first-child) {
    margin-bottom: -11px;
  }

  .row:first-child {
    margin-left: 129px;
    margin-bottom: -3px;
  }

  .category {
    width: 105px;
    font-size: 10px;
    min-height: 40px;
    max-height: 105px;
    margin-top: 8px;
  }

  .row-category{
    width: 80px;
  }

  .box {
    width: 105px;
    height: 105px;
  }

  .rarity {
    font-size: 7px;
    padding: 0;
  }

  .country-name {
    font-size: 7px;
    padding: 0;
  }
}

@media (max-width: 480px) {
  .board {
    display: flex;
    flex-direction: column;
    margin-left: -65px;
  }
  .row {
    margin-left: 55px;
  }
  .row:first-child {
    margin-left: 115px;
    margin-bottom: 2px;
  }
  .row:not(:first-child) {
    margin-bottom: -1px;
  }
  .category {
    width: 85px;
    font-size: 8px;
    min-height: 30px;
    max-height: 85px;
    margin-top: 3px;
  }
  .row-category{
    width: 56px;
  }
  .box {
    width: 85px;
    height: 85px;
  }

  .rarity {
    font-size: 6px;
    line-height: 9px;
  }
  .country-name {
    font-size: 6px;
    line-height: 9px;
  }
}

@media (max-width: 375px) {
  .row{
    height: 90px;
  }
}

@media (max-width: 768px), (max-width: 480px) {
  body {
    overflow: hidden;
  }
}
</style>
