<template>
  <div v-if="localLiveRarityData" class="game-over" @click.self="$emit('close')">
    <div class="content">
      <h1 class="game-summary-title">Game Summary</h1>
      <h2 class="game-summary-come-back-msg">⏰ Come back to play the new board tomorrow! ⏰</h2>
      <h3 class="board-title">🌎 Board #{{ boardId }} 🌎</h3>
      <div class="stats-container">
        <div class="board-info">
          <div class="emoji-grid">
            <span v-for="(row, rowIndex) in board" :key="rowIndex" style="min-width: 200px;">
              {{ row.map(cell => (cell === '' ? '❌' : '✅')).join('') }}
              <br v-if="rowIndex !== board.length - 1">
            </span>
          </div>  
        </div>
        <div class="score-container">
          <h2 class="score-text">Your Score</h2>
          <h3>
            <p class="score-rank"><span>Score: </span><span style="font-weight: normal;">{{ score }}</span></p>
            <p class="rank-rank"><span >Rank: </span><span style="font-weight: normal;">{{ formatNumber(rank) }} / {{ formatNumber(plays) }}</span></p>
          </h3>
          <div class="button-container">
            <button ref="shareButton" class="share-btn" @click="copyText($event)">Share</button>
            <button class="tweet-btn" @click="openTwitter" style="background-color: black;">
              <img class="twitter-img" src="/xlogo.png" alt="Twitter Icon" style="width: 15px; height: 15px;"> Post
            </button>
          </div>
        </div>
      </div>

     

      <h1 class="most-popular-msg section-title">Most Popular Answers</h1>
      <popularAnswerBoard class="popular-answer-board" :score="score" :boardId="boardId" :board="board"
        :liveRarityData="liveRarityData" :gameData="gameData" :countryData="countryData"></popularAnswerBoard>
      <h1 class="section-title">Least Popular Answers</h1>
      <leastCommonAnswers class="popular-answer-board" :score="score" :boardId="boardId" :board="board"
        :liveRarityData="liveRarityData" :gameData="gameData" :countryData="countryData"></leastCommonAnswers>
      <h1 class="section-title">Answer Frequency</h1>
      <answerFreq class="popular-answer-board" :score="score" :board="board" :boardId="+boardId"
        :localLiveRarityData="localLiveRarityData" :gameData="gameData" :plays="plays"></answerFreq>
      <h1 class="section-title">Board Answers</h1>
      <showAnswersBoard class="popular-answer-board" :score="score" :board="board" :liveRarityData="liveRarityData"
        :gameData="gameData" :plays="plays" :answersList="answersList"></showAnswersBoard>
      <h2 class="game-summary-come-back-msg" style="margin-top: 30px; margin-bottom: -10px">⏰New board at 0:00am, local
        time!⏰</h2>
      <button class="close-btn" @click="closeGameOverPopup">Close</button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import popularAnswerBoard from './popularAnswerBoard.vue';
import answerFreq from './answerFreq.vue';
import showAnswersBoard from './showAnswersBoard.vue';
import leastCommonAnswers from './leastCommonAnswers.vue';
import config from '../axiospath.js';

export default {
  components: {
    popularAnswerBoard,
    answerFreq,
    showAnswersBoard,
    leastCommonAnswers
  },
  props: ['score', 'boardId', 'board', 'liveRarityData', 'gameData', 'countryData', 'answersList'],
  data() {
    return {
      rank: null,
      plays: null,
      localLiveRarityData: JSON.parse(localStorage.getItem(`answer_freq_data_${this.boardId}`)),
    };
  },
  methods: {
    closeGameOverPopup() {
      this.$emit('close');
    },
    formatNumber(number) {
      if (number === null) return ''; // Return an empty string if the number is null
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    // Copy the game summary to the clipboard
    copyText(event) {
      event.preventDefault();

      const gridEmojis = this.board.map(row => row.map(cell => cell === '' ? '❌' : '✅').join(' ')).join('\n') + '\n\n';
      const textToCopy = `${gridEmojis}🌎Game Summary🌎\nBoard #${this.boardId}\nScore: ${this.score}\nRank: ${this.formatNumber(this.rank)} / ${this.formatNumber(this.plays)}\nhttps://geogridgame.com\n@geogridgame`;
      
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
        this.$refs.shareButton.innerText = 'Copied to clipboard!';
      } catch (err) {
        console.error('Unable to copy text', err);
      }

      document.body.removeChild(textArea);
    },

    // Set the leaderboard data
    async setLeaderboardData() {
      try {
        // Check if leaderboard data exists in local storage
        const localLeaderboardData = localStorage.getItem(`leaderboard_data_${this.boardId}`);

        if (localLeaderboardData) {
          const parsedData = JSON.parse(localLeaderboardData);
          this.rank = parsedData.rank;
          this.plays = parsedData.plays;
          return;
        }

        // Fetch leaderboard data if not found in local storage
        const response = await axios.post(`${config.baseURL}/game/stats/${this.boardId}?score=${this.score}&flag=0`);
        const scores = response.data.scores;
        const scoreRank = scores.indexOf(String(this.score)) + 1;


        const plays = response.data.plays;
        this.rank = scoreRank;
        this.plays = plays;

        // Save leaderboard data to local storage
        localStorage.setItem(`leaderboard_data_${this.boardId}`, JSON.stringify({ rank: scoreRank, plays }));
      } catch (error) {
        console.error('Error setting leaderboard data:', error);
      }
    },
    async setAnswerFrequencyData() {
  try {
    // Check if answer frequency data exists in local storage
    const localAnswerFreqData = localStorage.getItem(`answer_freq_data_${this.boardId}`);

    if (localAnswerFreqData) {
      this.localLiveRarityData = JSON.parse(localAnswerFreqData);
    } else {
      // Save the current value of liveRarityData
      const liveRarityData = this.liveRarityData;

      // Set the local storage item with liveRarityData
      localStorage.setItem(`answer_freq_data_${this.boardId}`, JSON.stringify(liveRarityData));
      
      // Update localLiveRarityData with the value from localStorage
      this.localLiveRarityData = liveRarityData;
    }
    } catch (error) {
      console.error('Error setting answer frequency data:', error);
    }
  },
  openTwitter() {
      const gridEmojis = this.board.map(row => row.map(cell => cell === '' ? '❌' : '✅').join(' ')).join('\n') + '\n\n';
      const tweetText = `${gridEmojis}🌎 Game Summary 🌎\nBoard #${this.boardId}\nScore: ${this.score}\nRank: ${this.formatNumber(this.rank)} / ${this.formatNumber(this.plays)}\ngeogridgame.com\n@geogridgame`;      const tweetUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweetText)}`;
      window.open(tweetUrl, '_blank');
    },
  },
  

  mounted() {
    this.localLiveRarityData = JSON.parse(localStorage.getItem(`answer_freq_data_${this.boardId}`));
    this.setLeaderboardData();
    this.setAnswerFrequencyData();
  }
}
</script>

<style scoped>
.game-over {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.game-summary-come-back-msg{
  font-size: 20px;
  margin: 30px 0 40px;
}

.section-title{
  margin: 50px 0;
}

.content {
  background-color: rgb(53, 53, 72);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  max-width: 600px;
  max-height: 70%;
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
}

.game-summary-title {
  margin-top: 0;
  font-size: 2.5rem;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  font-family: 'Poppins', Helvetica, Arial, sans-serif;
  background-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #bbb9b9;
}

.score-rank,.rank-rank{
  display: flex; 
  justify-content: space-between;
  margin: 0;
}

.board-title{
  font-size: 2rem;
  margin-bottom: 1rem;
}

.stats-container{
  display: flex;
  justify-content:center;
  gap: 35px;
}

.score-container{
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
}

.score-text{
  margin: 0;
  text-align: center;
}

.emoji-grid {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  width: max-content;
  font-size: 3em;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
}

.emoji-grid span{
  display: inline-block;
}

.emoji-grid span:not(:last-child) {
  margin-bottom: -12px;
}

.board-info {
  margin-top: -10px;
  height: 100%;
}

.button-container{
  display: flex;
  justify-content: stretch;
  gap: 10px;
}

.share-btn, .tweet-btn {
  padding: 6px 18px;
  flex-grow: 1;
}

.tweet-btn:hover {
  background-color: #afacac !important;
}

.twitter-img {
  margin-bottom: -3px;
}


.close-btn {
  margin-top: 25px;
}

@media (max-width: 768px) {
  .popular-answer-board {
    height: 20%;
    width: 80%;
    padding-left: 150px;
  }
  .content {
    max-width: 355px;
  }
  .game-summary-come-back-msg{
    font-size: 15px;
    margin: 20px 0 40px;
  }
  .stats-container{
    flex-direction: column;
    align-items: center;
    gap: 0;
    margin-bottom: 12px;
  }
  .rank-rank, .score-rank{
    font-size: 16px;
  }

  .board-title{
    font-size: 1.8rem;
    margin-top: -10px;
  }

  .section-title{
    font-size: 1.7rem;
    margin: 30px 0;
  }
}

@media (max-width: 480px) {
  .game-over .content .popular-answer-board {
    height: 20%;
    width: 80%;
    padding-left: 30px;
  }
  .content {
    max-width: 255px;
  }
  .game-summary-title{
    line-height: 1.3;
  }
  .score-text{
    font-size: 1.2rem;
  }
  
  .board-title{
    font-size: 1.6rem;
  }

  .section-title{
    font-size: 1.4rem;
    margin: 20px 0;
  }

  .rank-rank, .score-rank{
    font-size: 14px;
  }
}

</style>