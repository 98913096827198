<template>
    <div class="game-info" @click.self="$emit('close')">
      <div class="content">
        <span class="close-button" @click="$emit('close')">x</span>
        <h1>Categories Atlas:</h1>
        <p class="click-msg">Click on a category to learn more:</p>
        <div class="category-list">
          <span class="category" @click="scrollToCategory('category1')">Population count (stats)</span>
          <span class="category" @click="scrollToCategory('category7')">Area in km² (stats)</span>
          <span class="category" @click="scrollToCategory('category10')">Coastal distance (stats)</span>
          <span class="category" @click="scrollToCategory('category11')">Capital city population (stats)</span>
          <span class="category" @click="scrollToCategory('category2')">Flag with color</span>
          <span class="category" @click="scrollToCategory('category3')">Human Development Index</span>
          <span class="category" @click="scrollToCategory('category4')">GDP per capita</span>
          <span class="category" @click="scrollToCategory('category5')">Olympic medal count</span>
          <span class="category" @click="scrollToCategory('category6')">Is island nation</span>
          <span class="category" @click="scrollToCategory('category8')">Is landlocked</span>
          <span class="category" @click="scrollToCategory('category9')">Has star in flag</span>
          <span class="category" @click="scrollToCategory('category12')">Starts/Ends with letter</span>
          <span class="category" @click="scrollToCategory('category13')">Name is # letters long</span>
          <span class="category" @click="scrollToCategory('category14')">Name is multiple words</span>
          <span class="category" @click="scrollToCategory('category15')">Borderes #-# countries</span>
          <span class="category" @click="scrollToCategory('category16')">Has won the Men's FIFA World Cup</span>
          <span class="category" @click="scrollToCategory('category17')">Borders Russia/China/Brazil/France</span>
          <span class="category" @click="scrollToCategory('category18')">Member of the European Union</span>
          <span class="category" @click="scrollToCategory('category19')">Flag with only red, white, and blue</span>
          <span class="category" @click="scrollToCategory('category20')">Flag without red, white, or blue</span>
          <span class="category" @click="scrollToCategory('category21')">Drives on the left</span>
          <span class="category" @click="scrollToCategory('category22')">Has 50+ skyscrapers</span>
          <span class="category" @click="scrollToCategory('category23')">Top 20 in obesity rate</span>
          <span class="category" @click="scrollToCategory('category24')">Alcohol prohibition</span>
          <span class="category" @click="scrollToCategory('category25')">Top 20 in alcohol consumption</span>
          <span class="category" @click="scrollToCategory('category26')">Top 20 in chocolate consumption</span>
          <span class="category" @click="scrollToCategory('category27')">Has nuclear weapons</span>
          <span class="category" @click="scrollToCategory('category28')">Top/Bottom 20 in population density</span>
          <span class="category" @click="scrollToCategory('category29')">Top 20 in annual tourist arrivals</span>
          <span class="category" @click="scrollToCategory('category30')">Spanish/French/Arabic speaking</span>
          <span class="category" @click="scrollToCategory('category31')">Was part of the USSR</span>
          <span class="category" @click="scrollToCategory('category32')">Flag has a coat of arms</span>
          <span class="category" @click="scrollToCategory('category33')">Flag has an animal</span>
          <span class="category" @click="scrollToCategory('category34')">Coastline on the Mediterranean Sea</span>
          <span class="category" @click="scrollToCategory('category35')">Has hosted the olympics</span>
          <span class="category" @click="scrollToCategory('category36')">Has hosted the Men's FIFA World Cup</span>
          <span class="category" @click="scrollToCategory('category37')">Has played in the Men's FIFA World Cup</span>
          <span class="category" @click="scrollToCategory('category38')">Flag with only # colors</span>
          <span class="category" @click="scrollToCategory('category39')">Member of the Commonwealth</span>
          <span class="category" @click="scrollToCategory('category40')">Has never won an Olympic medal</span>



        </div>
        <div class="category-description" id="category1">
          <h2>Population count</h2>
          <p class="desc"> Over or under specified population. <a href="https://www.cia.gov/the-world-factbook/field/population/country-comparison/" target="_blank" style="color: white;">Source</a>
            <br><br>Useful statistics:<br>
            25th percentile: 2,000,000<br>
            50th percentile: 8,000,000<br>
            75th percentile: 30,000,000<br>
            Average: 40,000,000<br></p>
        </div>
        <div class="category-description" id="category7">
          <h2>Area km²</h2>
          <p class="desc"> Over or under specified area in km². <a href="https://www.cia.gov/the-world-factbook/field/area/" target="_blank" style="color: white;">Source</a>
            <br><br>Note that this excludes overseas departments (e.g. Greenland does not impact the area of Denmark).
            <br><br>Useful statistics:<br>
            25th percentile: 22,000 km²<br>
            50th percentile: 115,000 km²<br>
            75th percentile: 524,000 km²<br>
            Average: 675,000 km²<br></p>
        </div>
        <div class="category-description" id="category10">
          <h2>Coastal distance</h2>
          <p class="desc"> Over or under specified length of coastline. <a href="https://www.cia.gov/the-world-factbook/field/coastline/" target="_blank" style="color: white;">Source</a>
            <br><br>Note that this excludes overseas departments (e.g. Greenland does not impact the coastal distance of Denmark).
            <br><br>Useful statistics:<br>
            25th percentile: 72 km<br>
            50th percentile: 445 km<br>
            75th percentile: 2,400 km<br>
            Average: 3,800 km<br></p>
        </div>
        <div class="category-description" id="category11">
          <h2>Capital city population</h2>
          <p class="desc"> Over or under specified population of population of capital city.<br><br>The population statistics refer only to the official capital area, and does not include the wider metropolitan/urban district. <a href="https://en.wikipedia.org/wiki/List_of_national_capitals_by_population" target="_blank" style="color: white;">Source</a>
            <br><br>Useful statistics:<br>
            25th percentile: 282,000<br>
            50th percentile: 1,134,000<br>
            75th percentile: 2,801,000<br>
            Average: 2,765,000<br></p>
        </div>
        <div class="category-description" id="category2">
          <h2>Flag with color</h2>
          <p class="desc"> Color must appear on the flag. Outlines do not count.</p>
        </div>
        <div class="category-description" id="category3">
          <h2>Human Development Index</h2>
          <p class="desc"> The Human Development Index (HDI) is a summary measure of average achievement in key dimensions of human development: a long and healthy life, being knowledgeable and having a decent standard of living. The HDI is the geometric mean of normalized indices for each of the three dimensions. <br><br> Over or under specified Human Development Index. <a href="https://hdr.undp.org/data-center/country-insights#/ranks" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category4">
          <h2>GDP per capita</h2>
          <p class="desc">Gross domestic product (GDP) per capita is a measure of a country's economic output per person.<br><br> Over or under specified GDP Per Capita. <a href="https://data.worldbank.org/indicator/NY.GDP.PCAP.CD?most_recent_value_desc=true" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category5">
          <h2>Olympic medal count</h2>
          <p class="desc">Over or under specified Olympic medals across all Olympic games, both Summer and Winter. <a href="https://en.wikipedia.org/wiki/All-time_Olympic_Games_medal_table" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category6">
          <h2>Island nation</h2>
          <p class="desc"> Is an island nation</p>
        </div>
        <div class="category-description" id="category8">
          <h2>Landlocked</h2>
          <p class="desc"> Does not border an ocean or a sea</p>
        </div>
        <div class="category-description" id="category9">
          <h2>Star in flag</h2>
          <p class="desc"> Has / Does not have a star in the flag. This includes symbols of the sun</p>
        </div>
        <div class="category-description" id="category12">
          <h2>Starts/Ends with letter</h2>
          <p class="desc"> Starts or ends with specified letter</p>
        </div>
        <div class="category-description" id="category13">
          <h2>Name is # letters long</h2>
          <p class="desc"> Name is specified number of letters long</p>
        </div>
        <div class="category-description" id="category14">
          <h2>Name is multiple words</h2>
          <p class="desc"> Name consists of multiple words</p>
        </div>
        <div class="category-description" id="category15">
          <h2>Borders countries</h2>
          <p class="desc"> Borders specified number of countries.<a href="https://www.cia.gov/the-world-factbook/field/land-boundaries/" target="_blank" style="color: white;">Source</a>
          <br><br>Note that this does not include overseas departments (e.g. Brazil and Suriname do not border France via French Guiana).</p>
        </div>
        <div class="category-description" id="category16">
          <h2>Has won the Men's FIFA World Cup</h2>
          <p class="desc"> Has won the Men's FIFA World Cup</p>
        </div>
        <div class="category-description" id="category17">
          <h2>Borders Russia/China/Brazil/France</h2>
          <p class="desc"> Borders Russia/China/Brazil/France.
          <br><br>Note that this does not include overseas departments (e.g. Brazil and Suriname do not border France via French Guiana).</p>
        </div>
        <div class="category-description" id="category18">
          <h2>Member of the European Union</h2>
          <p class="desc"> Is a member of the European Union</p>
        </div>
        <div class="category-description" id="category19">
          <h2>Flag with only red, white, and blue</h2>
          <p class="desc"> Flag consists of only red, white, and blue</p>
        </div>
        <div class="category-description" id="category20">
          <h2>Flag without red, white, or blue</h2>
          <p class="desc"> Flag does not consist of red, white, or blue</p>
        </div>
        <div class="category-description" id="category21">
          <h2>Drives on the left</h2>
          <p class="desc"> Drives on the left side of the road. <a href="https://en.wikipedia.org/wiki/Left-_and_right-hand_traffic" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category22">
          <h2>Has 50+ skyscrapers</h2>
          <p class="desc"> Has 50 or more skyscrapers. <a href="https://en.wikipedia.org/wiki/List_of_countries_with_the_most_skyscrapers" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category23">
          <h2>Top 20 in obesity rate</h2>
          <p class="desc"> Top 20 in obesity rate. <a href="https://en.wikipedia.org/wiki/List_of_countries_by_obesity_rate" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category24">
          <h2>Alcohol prohibition</h2>
          <p class="desc"> Has alcohol prohibition. <a href="https://en.wikipedia.org/wiki/List_of_countries_with_alcohol_prohibition" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category25">
          <h2>Top 20 in alcohol consumption</h2>
          <p class="desc"> Top 20 in alcohol consumption. <a href="https://en.wikipedia.org/wiki/List_of_countries_by_alcohol_consumption_per_capita" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category26">
          <h2>Top 20 in chocolate consumption</h2>
          <p class="desc"> Top 20 in chocolate consumption. <a href="https://en.wikipedia.org/wiki/List_of_countries_by_chocolate_consumption_per_capita" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category27">
          <h2>Has nuclear weapons</h2>
          <p class="desc"> Has nuclear weapons. <a href="https://en.wikipedia.org/wiki/List_of_states_with_nuclear_weapons" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category28">
          <h2>Top/Bottom 20 in population density</h2>
          <p class="desc"> Top/Bottom 20 in population density, typically expressed as the number of people per km2 of land area. <a href="https://en.wikipedia.org/wiki/List_of_countries_and_dependencies_by_population_density" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category29">
          <h2>Top 20 in annual tourist arrivals</h2>
          <p class="desc"> Top 20 in annual tourist arrivals. <a href="https://en.wikipedia.org/wiki/World_Tourism_rankings" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category30">
          <h2>Spanish/French/Arabic speaking</h2>
          <p class="desc"> Spanish/French/Arabic speaking. <a href="https://en.wikipedia.org/wiki/List_of_countries_where_Spanish_is_an_official_language" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category31">
          <h2>Was part of the USSR</h2>
          <p class="desc"> Was part of the USSR. <a href="https://en.wikipedia.org/wiki/Former_Soviet_Union_countries" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category32">
          <h2>Flag has a coat of arms</h2>
          <p class="desc"> A coat of arms is a distinctive heraldic bearings or shield of a person, family, corporation, or country. <a href="https://en.wikipedia.org/wiki/List_of_national_flags_by_design" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category33">
          <h2>Flag has an animal</h2>
          <p class="desc"> Flag contains a depiction of an animal (this includes humans). <a href="https://en.wikipedia.org/wiki/List_of_national_flags_by_design" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category34">
          <h2>Coastline on the Mediterranean Sea</h2>
          <p class="desc"> Has a coastline on the Mediterranean Sea. <a href="https://en.wikipedia.org/wiki/Mediterranean_Sea" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category35">
          <h2>Has hosted the Olympics</h2>
          <p class="desc"> Has hosted the summer or winter Olympics. <a href="https://en.wikipedia.org/wiki/List_of_Olympic_Games_host_cities" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category36">
          <h2>Has hosted the Men's FIFA World Cup</h2>
          <p class="desc"> Has hosted the Men's FIFA World Cup. <a href="https://en.wikipedia.org/wiki/FIFA_World_Cup_hosts" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category37">
          <h2>Has played in the Men's FIFA World Cup</h2>
          <p class="desc"> Has played in the Men's FIFA World Cup. <a href="https://en.wikipedia.org/wiki/National_team_appearances_in_the_FIFA_World_Cup" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category38">
          <h2>Flag with only # colors</h2>
          <p class="desc"> Country with a flag consisting of only # colors.</p>
        </div>
        <div class="category-description" id="category39">
          <h2>Member of the Commonwealth</h2>
          <p class="desc"> Country is a member of the Commonwealth. <a href="https://en.wikipedia.org/wiki/Member_states_of_the_Commonwealth_of_Nations" target="_blank" style="color: white;">Source</a></p>
        </div>
        <div class="category-description" id="category40">
          <h2>Has never won an Olympic medal</h2>
          <p class="desc"> Country that has never won an Olympic medal. <a href="https://en.wikipedia.org/wiki/All-time_Olympic_Games_medal_table" target="_blank" style="color: white;">Source</a></p>
        </div>
          <button @click="$emit('close')">Close</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    methods: {
      scrollToCategory(categoryId) {
        const categoryElement = document.getElementById(categoryId);
        if (categoryElement) {
          categoryElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    }
  };
  </script>
  
  <style scoped>
.game-info {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.content {
  position: relative;
  background-color: rgb(53, 53, 72);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
  text-align: center;
  width: 30%;
  max-height: 80%;
  overflow-y: auto;
  color:#dfdcdc;
  font-weight: bold;
  font-size: 14px;
}

h1, h2, h3 {
  color: #fff;
}

h1, p {
  word-wrap: break-word;
}

button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgb(141, 139, 139);
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
  font-size: 20px;
  color: #ffffff;
}

.close-button::before {
  content: 'x';
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
  background-color: transparent;
  z-index: -1;
}

.close-button:hover::before {
  background-color: #f2f2f2;
}


.category-list {
  display: flex;
  flex-direction: column;
}

.category {
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;

}

.category:hover {
  text-decoration: underline;
}

.desc, .click-msg {
  color: rgb(197, 203, 238);
}
/* .category {
   text-decoration: underline;
} */

@media screen and (max-width: 768px) {
  .content {
    width: 70%
  }
  .category {
  cursor: pointer;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 16px;

}
}
</style>